<template>
  <footer>
    <div class="footer-content">
      <div>
        <ul class="footer-nav clearfix">
          <li><router-link to="/Impressum">Impressum</router-link></li>
          <li><router-link to="/Datenschutz">Datenschutz</router-link></li>
        </ul>
      </div>
      <div>
        Wackenbergstraße&nbsp;84&nbsp;–&nbsp;88 &middot; 13156&nbsp;Berlin
      &middot;&nbsp;Tel.:&nbsp;030&nbsp;47489168 &middot; Fax:&nbsp;030&nbsp;47489172
      &middot;&nbsp;info@estel-power.de
      &middot;&nbsp;&copy; {{ year }}
      </div>

    </div>
  </footer>
</template>

<script>
import { computed } from 'vue'

export default {
  setup () {
    const year = computed(() => new Date().getFullYear())
    return { year }
  }
}
</script>

<style lang="scss">
  @import "./../style/cssVars";
  footer {
    background-color: #fff;
    padding-top: 1rem;
    font-size: 0.95rem;

    .footer-content {
      color: #fff;
      background-color: $company-color-blue;
      padding: 1rem;
    }

    ul.footer-nav {
      list-style: none;
      margin: 0 auto;
      padding: 0;

      li {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        float: left;
      }

      a {
        color: #fff;
        text-decoration: none;
      }
    }
  }
</style>