<template>
  <h1>Impressum</h1>
  <p>
    Angaben gemäß § 5 TMG:
    <br />Gerald Estel Anlagenmontagen & Schweißtechnik GmbH
    <br />Wackenbergstraße 84-88
    <br />13156 Berlin
    <br />
    <br />Telefon: 030 474891638
    <br />E-Mail: kontakt@estel-power.de
    <br />www.estel-power.de
    <br />
    <br />Registergericht: AG Charlottenburg
    <br />Registernummer: HRB 195370 B
    <br />Geschäftsführerin: Alexandra Nürnberger
    <br />USt-ID: DE317422198
  </p>
</template>

<script>
export default {
  created() {
    this.$store.state.headerImageParams = {
      name: 'header_',
      filetype: 'jpg',
      range: {
        start: 21,
        end: 27
      }
    }
  }
}
</script>

<style lang="scss">
@import "./../style/cssVars";
</style>