import { createRouter, createWebHistory } from 'vue-router'
import pageLeistungenAnlagenmontagen from '../views/Leistungen/pageAnlagenmontagen.vue'
import pageLeistungenSchweisstechnik from '../views/Leistungen/pageSchweisstechnik.vue'
import pageInstandhaltungen from '../views/Leistungen/pageInstandhaltungen'
import pageLeistungenIndex from '../views/Leistungen/pageIndex.vue'
import PageHome from '../views/PageHome'
import PageIntro from '../views/PageIntro'
import PageImprint from '../views/PageImprint'
import PagePrivacy from '../views/PagePrivacy'
import pageCareer from '../views/pageCareer'
import pageContact from '../views/pageContact'
import pageSendMailSuccess from '../views/pageSendMailSuccess'

const routes = [
  {
    path: '/',
    name: 'intro',
    component: PageIntro
  },
  {
    path: '/Datenschutz',
    name: 'datenschutz',
    component: PagePrivacy
  },
  {
    path: '/Impressum',
    name: 'impressum',
    component: PageImprint
  },
  {
    path: '/Karriere',
    name: 'career',
    component: pageCareer
  },
  {
    path: '/Kontakt',
    name: 'contact',
    component: pageContact
  },
  {
    path: '/Daten-gesendet',
    name: 'sendMailSuccess',
    component: pageSendMailSuccess
  },
  {
    path: '/Startseite',
    name: 'home',
    component: PageHome
  },
  {
    path: '/Leistungen',
    name: 'leistungen-index',
    label: 'Leistungen',
    component: pageLeistungenIndex
  },
  {
    path: '/Leistungen/Instandhaltungen',
    name: 'leistungen-instandhaltungen',
    label: 'Instandhaltungen',
    displaySubMenuLink: true,
    component: pageInstandhaltungen
  },
  {
    path: '/Leistungen/Schweisstechnik',
    name: 'leistungen-schweisstechnik',
    label: 'Schweißtechnik',
    displaySubMenuLink: true,
    component: pageLeistungenSchweisstechnik
  },
  {
    path: '/Leistungen/Anlagenmontagen',
    name: 'leistungen-anlagenmontagen',
    label: 'Anlagenmontagen',
    displaySubMenuLink: true,
    component: pageLeistungenAnlagenmontagen
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  }
})

export default router
