<template>
  <h1>Kontakt</h1>
  <div class="wrapper">
    <v-form ref="form">
      <v-row
          justify="space-between"
      >
        <v-text-field v-model="form.www" label="www" class="www-field" tabindex="-1"></v-text-field>

        <v-col cols="12">
          <v-text-field v-model="form.company" label="Firma" :rules="rules.text"></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field v-model="form.givenName" label="Vorname" :rules="rules.text"></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field v-model="form.lastName" label="Nachname" :rules="rules.text"></v-text-field>
        </v-col>

        <v-col cols="9" sm="8">
          <v-text-field v-model="form.street" label="Straße"></v-text-field>
        </v-col>
        <v-col cols="3" sm="4">
          <v-text-field v-model="form.houseNumber" label="Hausnummer"></v-text-field>
        </v-col>

        <v-col cols="3" sm="4">
          <v-text-field v-model="form.zip" label="PLZ"></v-text-field>
        </v-col>

        <v-col cols="9" sm="8">
          <v-text-field v-model="form.city" label="Ort"></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field v-model="form.phone" label="Telefon"></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field v-model="form.email" label="Email" :rules="rules.email"></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-textarea v-model="form.message" label="Ihre Nachricht" :rules="rules.text"></v-textarea>
        </v-col>

        <v-col cols="12">
          <!--:disabled="!valid"-->

          <v-btn
              color="success"
              class="mr-4 app-btn"
              @click="submit"
          >absenden</v-btn>
          <span class="btn-alert">{{warning}}</span>
        </v-col>
      </v-row>
    </v-form>
  </div>

  Es gelten unsere <router-link to="Datenschutz">Datenschutzinformationen</router-link>.


</template>

<script>
export default {
  created() {
    this.$store.state.headerImageParams = {
      name: 'header_',
      filetype: 'jpg',
      range: {
        start: 21,
        end: 27
      }
    };
  },
  data() {
    return {
      warning: '',
      rules: {
        text: [
          v => !!v || 'Bitte füllen Sie dieses Feld aus.',
          v => (v && v.length >= 5) || 'Die Eingabe sollte mehr als 5 Zeichen haben.',
        ],
        email: [
          v => !!v || 'Bitte geben Sie eine E-Mail Adresse ein.',
          v => /.+@.+\..+/.test(v) || 'Die E-Mail Adresse ist nicht korrekt.',
        ],
      },
      form: {
        // honeypot
        www: "",
        company: "",
        givenName: "",
        lastName: "",
        street: "",
        houseNumber: "",
        zip: "",
        city: "",
        phone: "",
        email: "",
        message: ""
      }
    }
  },
  methods: {
    validate () {
      return this.$refs.form.validate()
    },
    submit() {
      this.validate().then((value => {
        if(!value.valid) {
          this.warning = "Bitte prüfen Sie die Formulareingaben."
        } else {
          this.warning = "";

          var vueApp = this,
              data = new FormData();

          data.append( "json", JSON.stringify( this.form ) );
          fetch("./sendmail.php", {
            method: "POST",
            body: data
          })
          .then(function(res){ return res.json(); })
          .then(function(data){
            console.log(data);
            if(data.state) {
              vueApp.$router.push({ name: 'sendMailSuccess' })
            } else {
              vueApp.warning = "Fehler beim senden der Daten."
            }
          })
        }
      }));
    }
  }
}
</script>

<style lang="scss">
  @import "./../style/cssVars";
  input {
    border-bottom-color: $company-color-blue;
  }
  .app-btn.bg-success {
    background-color: $company-color-blue !important;
  }
</style>

<style scoped>
  .wrapper {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
	.btn-alert {
    color: rgb(176,0,32);
  }
  .www-field {
    position: absolute;
    left: -9000px;
    top: -9000px;
  }
</style>
