<template>
  <h1>Karriere</h1>
</template>

<script>
export default {
  created() {
    this.$store.state.headerImageParams = {
      name: 'header_',
      filetype: 'jpg',
      range: {
        start: 21,
        end: 27
      }
    };
  }
}
</script>

<style lang="scss">
@import "./../style/cssVars";
</style>