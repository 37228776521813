<template>

  <h1>Ihr Montage- und Servicepartner für Kraftwerkstechnik und Industrie</h1>

  <div class="Content">
    Seit der Unternehmensgründung im April 2000 führen wir Kraftwerkserviceleistungen (Umbau, Neubau, Instandhaltung, Revisionen und Reparaturen) für Unternehmen der Energieerzeugung, Industriekraftwerke, Müllverbrennungs- und Biomasseanlagen in Deutschland und vielen europäischen Ländern durch.
    <br />
    <br />Wir sind als Hersteller und Schweißbetrieb gemäß AD 2000 - Merkblatt HP0 und HP100R gemäß DGRL 2014/68/EU und DIN EN ISO 3834-3, für tragende Stahlbauten nach EN 1090-1, EN1090-2 bis EXC3 sowie als Fachbetrieb nach Fachbetrieb Wasserhaushaltsgesetz gemäß §62 AwSV zugelassen und arbeiten nach Managementsystemen auf der Grundlage der EN ISO 9001:2015 und des SCCP-VAZ 2021-Regelwerkes.
    <br />
    <br />Unsere Mitarbeiter verfügen durch die langjährige Projekt- und Montagetätigkeit über umfangreiche Qualifikationen und Erfahrungen im Kessel- und Anlagenbau (Großdampferzeuger, Industrie-, Abhitze-, Biomasse- und Müllkessel, Chemieanlagen etc.).
    <br />
    <br />Überwiegend werden die Projekte als Komplettleistungen einschließlich Schweißaufsicht, Schweißnahtprüfung, Dokumentation und Wärmebehandlung mit eigenem Werkzeug und Geräten zum abgewickelt.
    <br />
    <br />Spezialleistungen und zusätzliche Montagekapazitäten werden den Projekterfordernissen entsprechend eingekauft. Qualität, Erfahrung und Zuverlässigkeit sind dabei wichtigste Auswahlkriterien.
  </div>

</template>

<script>
export default {
  created() {
    /*
    let min = 21,
        max = 27,
        imageNumber = Math.round((Math.random() * (max - min)) + min);
    console.log(imageNumber);
    this.$store.state.headerImage = 'header_' + imageNumber + '.jpg';
     */
    this.$store.state.headerImageParams = {
      name: 'header_',
      filetype: 'jpg',
      range: {
        start: 21,
        end: 27
      }
    };
  },
}
</script>

<style lang="scss">
  @import "./../style/cssVars";
</style>