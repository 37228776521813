<template>
  <div v-if="hasHeaderImage" class='headerImage' :style="{backgroundImage: headerImage}"></div>
</template>

<script>

export default {
  computed: {
    headerImage() {
      return 'url(./images/' + this.$store.state.headerImage + ')';
    },
    hasHeaderImage() {
      this.resolveImage();
      return (this.$store.state.headerImage.length > 0);
    },
  },
  methods: {
    resolveImage() {
      if(this.$store.state.headerImageParams) {
        if(this.$store.state.headerImageParams.fullName) {
          this.$store.state.headerImage = this.$store.state.headerImageParams.fullName;
        } else if(
            this.$store.state.headerImageParams.name
            && this.$store.state.headerImageParams.filetype
            && this.$store.state.headerImageParams.range
            && this.$store.state.headerImageParams.range.start
            && this.$store.state.headerImageParams.range.end
        ) {
          let min = this.$store.state.headerImageParams.range.start,
              max = this.$store.state.headerImageParams.range.end,
              imageNumber = Math.round((Math.random() * (max - min)) + min),
              randomImage = this.$store.state.headerImageParams.name + imageNumber + '.' + this.$store.state.headerImageParams.filetype;

          if(
              this.$store.state.headerImage
              && this.$store.state.headerImage === randomImage
              && (max - min > 0)
          ) {
            if(imageNumber>min) {
              imageNumber = imageNumber-1;
            } else {
              imageNumber++;
            }
            randomImage = this.$store.state.headerImageParams.name + imageNumber + '.' + this.$store.state.headerImageParams.filetype;
          }
          this.$store.state.headerImage = randomImage;
        }
      } else {
        this.$store.state.headerImage = 'header_default.jpg';
      }

    }
  }
}

</script>

<style lang="scss">
@import "./../style/cssVars";
 .headerImage {
   width: 100%;
   height: 30vw;
   max-height: 250px;
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center center;
   margin-bottom: 1rem;
 }
</style>