<template>
  <h1>Vielen Dank</h1>
  <p>
    Herzlichen Dank für Ihre Nachricht.
    Wir werden uns schnellstmöglich mit Ihnen in Verbindung setzen.
  </p>
</template>

<script>
export default {
  created() {
    this.$store.state.headerImageParams = {
      name: 'header_',
      filetype: 'jpg',
      range: {
        start: 21,
        end: 27
      }
    }
  }
}
</script>

<style lang="scss">
@import "./../style/cssVars";
</style>