<template>

  <h1>Maschinentechnische Anlagenmontagen</h1>
  <div class="content">
    Wir übernehmen die Planung, Ausführung und Überwachung von De- und Montagen, Revisionen und Reparaturen  an Dampferzeuger- und Feuerungsanlagen aller Bauarten, Hochdruckrohrleitungen und
    <br />Druckbehältern einschließlich der Lieferung von Material, Ersatzteilen und Baugruppen.
  </div>
</template>

<script>
export default {
  created() {
    this.$store.state.headerImage = 'header_1.jpg';
  },
}
</script>