<template>
  <div class="textZoom"
       data-list="Neubauten,Umbauten,Modernisierungen,Instandhaltungen,Revisionen,Reparaturen"
  ></div>
  <div class="Content">

  </div>

  <h1>Unsere Leistungen</h1>

  <div class="content">
    Neubauten · Umbauten · Modernisierungen · Instandhaltungen · Revisionen · Reparaturen
  </div>

  <h2>Instandhaltungen</h2>
  <div class="content">
    Wir führen Instandhaltungsarbeiten, Revisionen und Reparaturen für Unternehmen der Energieerzeugung, Industriekraftwerke, Müllverbrennungs- und Biomasseanlagen durch.
    <br />
    <br />Unser Leistungsspektrum umfasst u. a.
    <ul>
      <li>Revisionen an Komponenten der Feuerungs-und Entaschungsanlagen, sowie der Brennstoffversorgung von Kraftwerksanlagen</li>
      <li>Reparaturen und Wartungen an Dampferzeugerbaugruppen wie z.B. Kesseltrommeln, Überhitzer, Verdampfer, Economiser, Membranrohrwänden u.ä.</li>
      <li>Reparaturen und Wartungen an Behältern, Apparaten, Rohrleitungen, sowie Armaturen der Dampf-und Drucktechnik</li>
      <li>Mitwirkung bei der Durchführung von Lebensdauerüberwachungsprogrammen (LDÜ)</li>
      <li>Arbeiten bei der Durchführung von Inneren und Äußeren Prüfungen an Wasserrohr- und Großwasserraumkesseln lt. EN 12952 und 12953</li>
      <li>Korrosionsschutz an Membranrohrwänden, Sammlern und Bündelheizflächen mittels Auftragsschweißung Inconell 625 (Cladding)</li>
    </ul>
  </div>

  <h2>Schweißtechnik</h2>
  <div class="content">
    Gern übernehmen wir die Planung, Überwachung und Ausführung von hochwertigen Schweißarbeiten in den verschiedensten Verfahren und Werkstoffgruppen.
    <br />
    <br />Es liegen sämtliche für die Branche erforderlichen Schweiß-Verfahrensprüfungen nach DIN EN ISO 15614-1 in den Verfahren MIG/MAG, WIG, E, WIG-Orbital und WIG/E für Stähle der Werkstoffgruppen 1 bis 8, 10 und Nickellegierungen der Werkstoffgruppen 43, 44 und 46 sowie deren Mischverbindungen nach DIN EN ISO 15608 (siehe auch „Zusammenstellung der Verfahrensprüfungen“) vor. Des Weiteren besitzen wir die Stahlbauzulassung nach DIN EN 1090-1 für geschweißte Stahlbaukonstruktionen in den Werkstoffgruppen 1 bis 8.
  </div>


  <h2>Maschinentechnische Anlagenmontagen</h2>
  <div class="content">
    Wir übernehmen die Planung, Ausführung und Überwachung von De- und Montagen, Revisionen und Reparaturen  an Dampferzeuger- und Feuerungsanlagen aller Bauarten, Hochdruckrohrleitungen und
    <br />Druckbehältern einschließlich der Lieferung von Material, Ersatzteilen und Baugruppen.
  </div>
</template>

<style lang="scss">
@import "./../../style/cssVars";
</style>


<script>
export default {
  created() {
    this.$store.state.headerImage = 'header_1.jpg';
  },
  mounted() {
    let i;
    const wordListELement = document.querySelector('.textZoom'),
        wordList = wordListELement.getAttribute('data-list').split(',');

    console.log(wordList);

    for(i in wordList) {
      console.log(wordList[i]);


    }
  }
}
</script>