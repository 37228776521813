import { createApp } from 'vue';
import { createStore } from 'vuex';
import App from './App.vue';
import router from './router';

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
    components,
    directives,
});

const store = createStore({
    state: {
        headerImage: '',
        logoClicked: false
    },
});

const app = createApp(App);
app.use(router);
app.use(store);
app.use(vuetify);
app.mount('#app');
