<template>
  <div class="page-container content-width">
    <page-header></page-header>
    <div id="main-content">
      <router-view/>
    </div>
    <page-footer class="content-width"></page-footer>
  </div>
</template>

<script>
import PageFooter from './components/PageFooter'
import PageHeader from "@/components/PageHeader"

export default {
  name: 'App',
  components: {
    PageHeader,
    PageFooter,
  }
}
</script>

<style lang="scss">
@import "./style/cssVars";
@import "./style/cssGlobal";

.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 200px 1fr 150px;
  gap: 0px 0px;
  grid-template-areas:
    "header"
    "content"
    "footer";
}
#main-content {
  grid-area: content;
}
footer {
  grid-area: footer;
}



body {
  padding: 0;
  margin: 0;
  background-color: #9a9b9a;

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin: 0;

    .page-container {
      background-color: $content-background;
      /* todo anpassen -footer und -margins.... */
      height: 100vh;



      #main-content {
        text-align: left;
        //footer height
        padding-bottom: 150px;
        padding-left: 2rem;
        padding-right: 2rem;
        clear: both;
        background: $content-background;

      }

      footer {
        position: fixed;
        bottom: 0;
      }
    }
  }
}
</style>
