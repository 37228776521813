<template>
  <h1>Instandhaltungen</h1>
  <div class="content">
    Wir führen Instandhaltungsarbeiten, Revisionen und Reparaturen für Unternehmen der Energieerzeugung, Industriekraftwerke, Müllverbrennungs- und Biomasseanlagen durch.
    <br />
    <br />Unser Leistungsspektrum umfasst Kesselrevisionen, Lebensdauerüberwachungsprogramme (LDÜ), Reparaturen und den Austausch von:
    <ul>
      <li>Kesseltrommeln,</li>
      <li>Membranrohrwänden,</li>
      <li>Überhitzern und Einspritzkühlern,</li>
      <li>Verdampferbündeln,</li>
      <li>Luftvorwärmern,</li>
      <li>Apparaten und Behältern</li>
      <li>Economisern und HD-Rohrleitungen</li>
      <li>Umbau und Reparaturen von Dampfleitungen,</li>
      <li>Revisionen und Reparaturen an Feuerungs-, Brennstoffversorgungs- und Entaschungsanlagen</li>
      <li>Cladding (Inconell 625) von Membranrohrwänden, Sammlern und   Bündelheizflächen,</li>
      <li>u. v. m.</li>
    </ul>
  </div>
</template>

<script>
export default {
  created() {
    this.$store.state.headerImage = 'header_3.jpg';
  },
}
</script>