<template>
  <h1>Schweißtechnik</h1>
  <div class="content">
    Gern übernehmen wir die Planung, Überwachung und Ausführung von hochwertigen Schweißarbeiten in den verschiedensten Verfahren und Werkstoffgruppen.
    <br />
    <br />Es liegen sämtliche für die Branche erforderlichen Schweiß-Verfahrensprüfungen nach DIN EN ISO 15614-1 in den Verfahren MIG/MAG, WIG, E, WIG-Orbital und WIG/E für Stähle der Werkstoffgruppen 1 bis 8, 10 und Nickellegierungen der Werkstoffgruppen 43, 44 und 46 sowie deren Mischverbindungen nach DIN EN ISO 15608 (siehe auch „Zusammenstellung der Verfahrensprüfungen“) vor. Des Weiteren besitzen wir die Stahlbauzulassung nach DIN EN 1090-1 für geschweißte Stahlbaukonstruktionen in den Werkstoffgruppen 1 bis 8.
  </div>
</template>

<script>
export default {
  created() {
    this.$store.state.headerImage = 'header_1.jpg';
  },
  setup() {
    const tableData = [
      {
        title: '1 unlegierte Stähle',
        werkstoff: '16Mo3 P235GH, 1.8935',
        wig: 'Rohr, Blech',
        wigE: 'Rohr, Blech',
        e: 'Blech',
        wigOrbital: 'Rohr',
        migMag: 'Blech'
      },
      {
        title: '4 Feinkorn-Baustähle',
        data: [
          {
            werkstoff: '15 NiCuMoNb 5 (WB36)',
            wig: 'Rohr',
            wigE: 'Rohr',
            e: '',
            wigOrbital: '',
            migMag: ''
          }
        ]
      },
      {
        title: '5 warmfeste Stähle',
        data: [{
          werkstoff: '13CrMo 4 5\n' +
              '10CrMo 9 10\n' +
              '14MoV 63\n',
          wig: 'Rohr',
          wigE: 'Rohr',
          e: 'Blech',
          wigOrbital: '',
          migMag: ''
        }]
      },
      {
        title: '6 hochwarmfeste Stähle',
        data: [{
          werkstoff: 'P91, VM12-SHC\n' +
              'X20CrMoV 12 1, 12 CH1 MF\n',
          wig: 'Rohr',
          wigE: 'Rohr',
          e: '',
          wigOrbital: '',
          migMag: ''
        }]
      },
      {
        title: '7 ferritische, hitzebeständige Stähle',
        data: [{
          werkstoff: 'X10CrAlSi 7\n' +
              'X10CrAlSi 24\n',
          wig: '',
          wigE: 'Blech',
          e: 'Blech',
          wigOrbital: '',
          migMag: ''
        }]

      },
      {
        title: '8 austenitische, hitzebeständige Stähle\n' +
            'und allgemeine Edelstähle',
        data: [
          {
            werkstoff: 'X6 CrNiTi 18 10',
            wig: '',
            wigE: '',
            e: '',
            wigOrbital: 'Rohr',
            migMag: ''
          }, {
            werkstoff: 'X6 CrNiMoTi 17 12 2',
            wig: '',
            wigE: '',
            e: '',
            wigOrbital: 'Rohr',
            migMag: ''
          }, {
            werkstoff: 'X15 CrNiSi 20 12',
            wig: 'Rohr/Blech',
            wigE: 'Rohr',
            e: 'Blech',
            wigOrbital: '',
            migMag: ''
          }, {
            werkstoff: 'X15 CrNiSI 25 21',
            wig: '',
            wigE: '',
            e: '',
            wigOrbital: '',
            migMag: ''
          }, {
            werkstoff: 'X5 NiCrCeNb 32 27 (AC66)',
            wig: '',
            wigE: '',
            e: '',
            wigOrbital: '',
            migMag: ''
          }, {
            werkstoff: 'X3 CrNiMoN 17 13 3',
            wig: '',
            wigE: '',
            e: '',
            wigOrbital: '',
            migMag: ''
          },
        ]
      }
    ];
    return {tableData}
  },

}
</script>