<template>
  <header class="clearfix">
    <nav >
      <div class="introBackground invisible opacity-0"></div>
      <input type="checkbox" id="hamburg">
      <label for="hamburg" class="hamburg">
        <span class="line"></span>
        <span class="line"></span>
        <span class="line"></span>
      </label>

      <ul class="mainMenu" >
        <li><router-link to="/Startseite">Über uns</router-link></li>
        <li><router-link to="/Leistungen">Leistungen</router-link></li>
        <!-- <li><router-link to="/Karriere">Karriere</router-link></li> -->
        <li><router-link to="/Kontakt">Kontakt</router-link></li>
      </ul>
      <!--<sub-menu v-bind:menu-items="getSubMenuItems()"></sub-menu>-->
    </nav>
  </header>
  <div class="logo-container transition"
       v-on:click="clickLogo"
  >
    <div class="logo clearfix">
      <!-- img src="../assets/logo-estel.svg" -->
      <div class="logo-svg" v-html="logo">

      </div>
      <div class="logo-contact clearfix">
        <ul>
          <li>Telefon <a href="tel:03091425734">030 91425734</a></li>
          <li>E-Mail <a href="mailto:info@estel-power.de">info@estel-power.de</a></li>
        </ul>
      </div>
      <div class="clear"></div>
    </div>
    <div class="clear"></div>
  </div>
  <header-image></header-image>
</template>

<script>
// import SubMenu from "@/components/subMenu";
import HeaderImage from "@/components/headerImage";

export default {
  components: {
    //SubMenu,
    HeaderImage
  },

  computed: {
    currentRoute() {
      return this.$route;
    },
  },

  beforeMount() {
    console.log('HEADER');
    this.logoClicked = this.$store.state.logoClicked;
  },


  data: function() {
    return {
      logoClicked: false,
      logo: '<svg\n' +
          '   width="67.322998mm"\n' +
          '   height="99.962997mm"\n' +
          '   viewBox="0 0 67.322998 99.962997"\n' +
          '   version="1.1"\n' +
          '   id="svg5"\n' +
          '   inkscape:version="1.1.1 (c3084ef, 2021-09-22)"\n' +
          '   sodipodi:docname="Logo-Estel.svg"\n' +
          '   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"\n' +
          '   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"\n' +
          '   xmlns="http://www.w3.org/2000/svg"\n' +
          '   xmlns:svg="http://www.w3.org/2000/svg">\n' +
          '  <defs\n' +
          '     id="defs2">\n' +
          '    <clipPath\n' +
          '       clipPathUnits="userSpaceOnUse"\n' +
          '       id="clipPath838">\n' +
          '      <path\n' +
          '         d="M 0,0 H 66.614 V 99.213 H 0 Z"\n' +
          '         id="path836" />\n' +
          '    </clipPath>\n' +
          '    <clipPath\n' +
          '       clipPathUnits="userSpaceOnUse"\n' +
          '       id="clipPath846">\n' +
          '      <path\n' +
          '         d="M -0.283,-0.242 H 67.04 V 99.722 H -0.283 Z"\n' +
          '         id="path844" />\n' +
          '    </clipPath>\n' +
          '    <clipPath\n' +
          '       clipPathUnits="userSpaceOnUse"\n' +
          '       id="clipPath856">\n' +
          '      <path\n' +
          '         d="M -0.283,99.723 H 67.04 V -0.241 H -0.283 Z"\n' +
          '         id="path854" />\n' +
          '    </clipPath>\n' +
          '    <clipPath\n' +
          '       clipPathUnits="userSpaceOnUse"\n' +
          '       id="clipPath864">\n' +
          '      <path\n' +
          '         d="M -0.283,99.723 H 67.04 V -0.241 H -0.283 Z"\n' +
          '         id="path862" />\n' +
          '    </clipPath>\n' +
          '    <clipPath\n' +
          '       clipPathUnits="userSpaceOnUse"\n' +
          '       id="clipPath872">\n' +
          '      <path\n' +
          '         d="M -0.283,-0.242 H 67.04 V 99.722 H -0.283 Z"\n' +
          '         id="path870" />\n' +
          '    </clipPath>\n' +
          '  </defs>\n' +
          '  <g\n' +
          '     id="logo"\n' +
          '     clip-path="url(#clipPath872)"\n' +
          '     style="display:inline"\n' +
          '     inkscape:label="logo"\n' +
          '     transform="matrix(1,0,0,-1,0.283,99.722)">\n' +
          '    <path\n' +
          '       d="M 67.04,99.723 H -0.283 V -0.241 H 67.04 Z"\n' +
          '       style="display:inline;fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"\n' +
          '       id="background-white"\n' +
          '       inkscape:label="background-white" />\n' +
          '    <path\n' +
          '       d="M 0.736,24.385 H 65.97 V 98.702 H 0.736 Z"\n' +
          '       style="display:inline;fill:#2c57d2;fill-opacity:1;fill-rule:nonzero;stroke:none"\n' +
          '       id="blue"\n' +
          '       inkscape:label="blue" />\n' +
          '    <g\n' +
          '       id="arrow_top"\n' +
          '       inkscape:label="arrow_top"\n' +
          '       style="display:inline">\n' +
          '      <path\n' +
          '         d="M 33.104,37.296 V 81.853"\n' +
          '         style="fill:none;stroke:#ffffff;stroke-width:1.08;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"\n' +
          '         id="path880" />\n' +
          '      <path\n' +
          '         d="m 31.576,80.986 1.479,4.301 1.496,-4.301 z"\n' +
          '         style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"\n' +
          '         id="path882" />\n' +
          '    </g>\n' +
          '    <g\n' +
          '       id="arrow_topright"\n' +
          '       inkscape:label="arrow_topright"\n' +
          '       style="display:inline">\n' +
          '      <path\n' +
          '         d="M 13.892,50.165 50.475,71.272"\n' +
          '         style="display:inline;fill:none;stroke:#ffffff;stroke-width:1.08;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"\n' +
          '         id="path884" />\n' +
          '      <path\n' +
          '         d="m 48.96,72.162 4.465,0.869 -2.978,-3.445 z"\n' +
          '         style="display:inline;fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"\n' +
          '         id="path886" />\n' +
          '    </g>\n' +
          '    <g\n' +
          '       id="arrow_bottomright"\n' +
          '       inkscape:label="arrow_bottomright"\n' +
          '       style="display:inline">\n' +
          '      <path\n' +
          '         d="M 13.657,72.574 50.279,51.475"\n' +
          '         style="display:inline;fill:none;stroke:#ffffff;stroke-width:1.08;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"\n' +
          '         id="path888" />\n' +
          '      <path\n' +
          '         d="M 50.292,53.232 53.277,49.8 48.804,50.655 Z"\n' +
          '         style="display:inline;fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"\n' +
          '         id="path890" />\n' +
          '    </g>\n' +
          '    <g\n' +
          '       id="logo_text"\n' +
          '       inkscape:label="logo_text"\n' +
          '       style="display:inline">\n' +
          '      <text\n' +
          '         transform="matrix(1,0,0,-1,0.5846,15.8115)"\n' +
          '         style="font-variant:normal;font-weight:normal;font-stretch:normal;font-size:7.197px;font-family:Arial;-inkscape-font-specification:Arial;writing-mode:lr-tb;display:inline;fill:#231f20;fill-opacity:1;fill-rule:nonzero;stroke:none"\n' +
          '         id="text894"><tspan\n' +
          '           x="0 6.3261628 11.87505 17.812574 23.19593 28.061104 35.142952 40.080093 45.485039 51.012337 56.150993 61.555943"\n' +
          '           y="0"\n' +
          '           sodipodi:role="line"\n' +
          '           id="tspan892">GERALD ESTEL</tspan></text>\n' +
          '      <text\n' +
          '         transform="matrix(0.94999396,0,0,-1,0.4655,8.2547)"\n' +
          '         style="font-variant:normal;font-weight:normal;font-stretch:normal;font-size:6.6212px;font-family:Arial;-inkscape-font-specification:Arial;writing-mode:lr-tb;fill:#231f20;fill-opacity:1;fill-rule:nonzero;stroke:none"\n' +
          '         id="text898"><tspan\n' +
          '           x="0 4.1581135 8.8061962 12.884855 17.307817 22.465731 26.643709 31.291792 37.19128 42.355816 47.268745 50.857437 55.280399 60.305889 64.35144"\n' +
          '           y="0"\n' +
          '           sodipodi:role="line"\n' +
          '           id="tspan896">ANLAGENMONTAGEN</tspan></text>\n' +
          '      <text\n' +
          '         transform="matrix(0.94999396,0,0,-1,0.6054717,0.6979)"\n' +
          '         style="font-variant:normal;font-weight:normal;font-stretch:normal;font-size:6.6212px;font-family:Arial;-inkscape-font-specification:Arial;writing-mode:lr-tb;fill:#231f20;fill-opacity:1;fill-rule:nonzero;stroke:none"\n' +
          '         id="text902"><tspan\n' +
          '           x="0 4.1183863 5.4426265 9.8126183 14.672579 19.664965 26.272923 30.530354 32.457123 36.694691 40.932259 44.679859 48.67244 53.399979 58.127514 62.855053 64.516975"\n' +
          '           y="0"\n' +
          '           sodipodi:role="line"\n' +
          '           id="tspan900">&amp; SCHWEISSTECHNIK</tspan></text>\n' +
          '    </g>\n' +
          '  </g>\n' +
          '</svg>'
    }
  },

  methods: {
    clickLogo() {
        this.$store.state.logoClicked = !this.$store.state.logoClicked;
        this.logoClicked = this.$store.state.logoClicked;

      if(this.logoClicked) {
        // wir aktivieren transision, nur hier damit keine animation des Logos
        //beim resize passieren
        document.querySelector('.logo-container').classList.add('logoClicked');
        document.querySelector('.logo-container').classList.add('transition');

        console.log(document.querySelector('.logo-container').classList);
        document.querySelector('.hamburg').classList.add('invisible');
        document.querySelector('.introBackground').classList.remove('invisible');
        document.querySelector('.introBackground').classList.remove('opacity-0');
        document.querySelector('footer').classList.add('invisible');
      } else {
        document.querySelector('.hamburg').classList.remove('invisible');
        document.querySelector('.introBackground').classList.add('opacity-0');
        document.querySelector('.introBackground').classList.add('invisible');

        document.querySelector('.logo-container').classList.remove('logoClicked');
        setTimeout(() => {
          document.querySelector('footer').classList.remove('invisible');
          document.querySelector('.logo-container').classList.remove('transition');

        }, 1000);
      }
    },
    getSubMenuItems() {

      let currentRoute = this.currentRoute.path;
      let parentRoute = currentRoute.split('/');
      let subMenuItems = [];

      parentRoute.pop();
      parentRoute = parentRoute.join('/');

      this.$router.options.routes.forEach(route => {
        if(
            // childs
            (
                route.displaySubMenuLink === true &&
                currentRoute && currentRoute !== '/' &&
                route.path.indexOf(currentRoute) >= 0 &&
                currentRoute !== route.path
            )
            ||
            // siblings
            (
                route.displaySubMenuLink === true &&
                parentRoute && parentRoute !== '/' &&
                route.path.indexOf(parentRoute) >= 0 &&
                parentRoute !== route.path
            )
        ) {
          subMenuItems.push({
            route: route.path,
            label: route.label
          });
        }
      });


      return subMenuItems;
    }
  },

/*
      setup() {
        console.log("setup PageHeader");
      },
       beforeCreate() {
           console.log("beforeCreate PageHeader");
       },
       created() {
           console.log("created PageHeader");
       },
       beforeMount() {
           console.log("beforeMount PageHeader");
       },
       mounted() {
           console.log("mounted PageHeader");
       },
       beforeUpdate() {
           console.log("beforeUpdate PageHeader");
       },
       updated() {
           console.log("updated PageHeader");
       },
       beforeUnmount() {
           console.log("beforeUnmount PageHeader");
       },
       unmounted() {
           console.log("unmounted PageHeader");
       }
*/
}
</script>


<style lang="scss">
  @import "./../style/cssVars";

  .introBackground {
    transition: all 1s ease;
    position: fixed;
    left: 0;
    right: 0;
    top:0;
    bottom: 0;
    background-color: $company-color-blue;
  }

  header {
    grid-area: header;
    /*position: sticky;*/
    top: 0;
    width: 100%;
    background-color: #fff;
    float: none;
    clear: both;
    /*padding-bottom: 1rem;*/
    padding-top: 1rem;
    z-index: 99;

    nav {
      margin: 0;
      background-color: $company-color-blue;
      width: 100%;
      padding: 0;
      float: left;
      min-height: 4rem;

      ul.mainMenu {
          margin: 0;
          padding: 0;
          list-style: none;
          max-height: 0;
          overflow: hidden;

          @media screen and (min-width: 801px) {
            max-height:250px;
            /*damit nix unters logo rutscht*/
            width:80%;
          }

          li {
            @media screen and (min-width: 801px) {
              float: left;
              text-align: left;
            }
            padding: 1rem;
            color: #fff;
            font-size: 1.3rem;
            cursor: pointer;

            a {
              text-decoration: none;
              color: #fff;
            }
          }
        }
      }
    }





  .logo-container {
    position: absolute;
    float: right;
    cursor: pointer;
/*
    transform-origin: top right;
    transition: all 1s ease;
 */
    right: 1rem;
    top: 0rem;
    @media screen and (min-width: 801px) {
      right: 15%;
    }
    @media screen and (min-width: 1250px) {
      /* .content-width ist limitiert (1000px), müssen wir hier neuberechnen */
      /* seitenbreite/2 - 50% der Contentbreite + Randabstand */
      right: calc((100vw/2) - 500px + 2rem);
    }

    &.logoClicked {
      top: 1rem;
      transform: scale(4);
      background-color: #fff;
      /* desktop 80vw; mobile 100vw
      nue jetzt immer 100vw, da das logo position absolute ist */
      /* (contentbreite - logogobreite * zoom) / 2 = ((100vw/2)-88*5) / 2 */
      right: calc((100vw - 88*4px)/2);
    }

    &.logoClicked .logo-contact {
        visibility: visible;
    }

    .logo-contact {
      visibility: hidden;
      font-size: 0.4rem;

      ul {
        list-style: none;
        margin-left: 0;
        padding-left: 0;
        text-align: left;
        li {
          margin-left: inherit;
        }
      }
    }

    .logo-svg {
      height: 130px;
      width: 88px;



      svg {
        width: 100%;
        height: 100%;
      }

    }
    .logo-contact {
      visibility: hidden;
      padding-left: 0.1rem;
    }
  }
</style>

<!-- hamburger menu -->
<style lang="scss">

  label.hamburg {
    z-index: 99;
    display: block;
    width: 50px;
    height: 50px;
    position: absolute;
    left: 1rem;
    top: 1.5rem;

    @media screen and (min-width: 801px) {
      display: none;
    }
  }
  .line {
    position: absolute;
    left: 10px;
    height: 3px;
    width: 40px;
    background: #fff;
    border-radius: 2px;
    display: block;
    transition: 0.5s;
    transform-origin: center;
  }

  .line:nth-child(1) {
    top: 12px;
  }
  .line:nth-child(2) {
    top: 24px;
  }
  .line:nth-child(3) {
    top: 36px;
  }

  #hamburg:checked + .hamburg .line:nth-child(1) {
    transform: translateY(12px) rotate(-45deg);
  }

  #hamburg:checked + .hamburg .line:nth-child(2) {
    opacity: 0;
  }

  #hamburg:checked + .hamburg .line:nth-child(3) {
    transform: translateY(-12px) rotate(45deg);
  }


  #hamburg:checked + .hamburg + .mainMenu,
  #hamburg:checked + .hamburg ~ .subMenu {
    max-height: 600px;
  }

  input#hamburg {
    display:none;
  }



</style>
