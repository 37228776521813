<template>

  Intro

</template>

<script>
export default {

  data() {
    return {
      selectors: [
        'svg #arrow_topright', 'svg #arrow_bottomright', 'svg #arrow_top',
        'svg #blue', 'svg #background-white', 'svg #logo_text'
      ],
      stylesStart: {}
    }
  },

  created() {
    this.stylesStart.logoContainerBackground = document.querySelector('.logo-container').style.background;

    document.querySelector('.hamburg').classList.add('invisible');

    document.querySelector('.logo-container').style.background = 'transparent';
    document.querySelector('.logo-contact').classList.add('invisible');

    document.querySelector('.logo-container').classList.add('logoClicked');
    document.querySelector('.introBackground').classList.remove('invisible');
    document.querySelector('.introBackground').classList.remove('opacity-0');
    document.querySelector('footer').classList.add('invisible');

    for (let i in this.selectors) {
      document.querySelector(this.selectors[i]).classList.add('invisible');
    }
  },

  mounted() {
    console.log('mounted');
    let number = 3;
    for (let i in this.selectors) {
      number++;
      console.log(number);
      setTimeout(() => {
        document.querySelector(this.selectors[i]).classList.remove('invisible');
      }, 500 * number);
    }

    number = number + 3;
    setTimeout(() => {
      this.$router.push({name: 'home'})
      // alten state setzen
      document.querySelector('.hamburg').classList.remove('invisible');
      document.querySelector('.logo-container').style.background = this.stylesStart.logoContainerBackground;
      document.querySelector('.logo-contact').classList.remove('invisible');
      document.querySelector('.logo-container').classList.remove('logoClicked');
      document.querySelector('.introBackground').classList.add('invisible');
      document.querySelector('.introBackground').classList.add('opacity-0');
      setTimeout(() => {
        document.querySelector('footer').classList.remove('invisible');

        // dem logo die transition nehmen
        document.querySelector('.logo-container').classList.remove('transition');
      }, 1000);

    }, 500 * number);
  }
}
</script>

<style lang="scss">
@import "./../style/cssVars";
</style>